<template>
  <div class="invoice-order">
    <invoice-header text="开具电子发票" return-route-name="invoice"></invoice-header>
    <div class="invoice-order__list">
      <div class="invoice-order__list__item" v-for="(order,index) in orderList" :key="index">
        <div
          class="invoice-order-checkbox"
          :class="{'invoice-order-checkbox--active':order.select}"
          @click="onSelectClick(order)"
        ></div>
        <ul>
          <li>充值时间：{{order.time}}</li>
          <li>游戏ID：{{order.gameId}}</li>
          <li>订单号：{{order.payNo}}</li>
        </ul>
        <span>{{order.amount}}元</span>
      </div>
      <mugen-scroll :handler="fetchData" :should-handle="!loading">{{promptText}}</mugen-scroll>
    </div>
    <div class="invoice-order__bar">
      <p>
        <span class="num" v-text="selectOrderCount"></span>笔订单，共
        <span class="num" v-text="selectOrderTotalMoney"></span>元
      </p>
      <div class="select-all" @click="onSelectAll">
        <div class="invoice-order-checkbox" :class="{'invoice-order-checkbox--active':selectAll}"></div>全选
      </div>
      <button @click="onNextStep">下一步</button>
    </div>
  </div>
</template>

<script>
import util from "@/commons/util";
import { Decimal } from "decimal.js";
import { getOrder } from "./invoice.js";

export default {
  components: {
    "invoice-header": require("./header").default
  },
  created() {},
  data() {
    return {
      selectAll: false,
      orderList: [],
      loading: false,
      promptText: "",
      page: 1,
      pageSize: 10
    };
  },
  computed: {
    selectOrder() {
      return this.selectAll
        ? this.orderList
        : this.orderList.filter(item => item.select);
    },
    selectOrderCount() {
      return this.selectOrder.length;
    },
    selectOrderTotalMoney() {
      let sum = new Decimal(0);
      for (let i = 0; i < this.selectOrderCount; i++) {
        sum = sum.plus(new Decimal(this.selectOrder[i].amount));
      }
      return sum.toString();
    }
  },

  methods: {
    async fetchData() {
      this.loading = true;
      this.promptText = "加载中...";

      let orderList = await getOrder(this.page, this.pageSize);
      this.loading = false;

      if (orderList.length === 0) {
        this.promptText = "没有更多数据了";
      } else {
        orderList.map(item => {
          item.select = this.selectAll;
          return item;
        });

        this.page += 1;
        this.orderList.push(...orderList);
        this.promptText = "";
      }
    },
    onSelectClick(order) {
      order.select = !order.select;
      this.selectAll = this.orderList.every(item => item.select);
    },
    onSelectAll() {
      this.selectAll = !this.selectAll;
      this.orderList.forEach(item => (item.select = this.selectAll));
    },
    onNextStep() {
      if (this.selectOrderCount > 0) {
        if(new Decimal(this.selectOrderTotalMoney).greaterThan(new Decimal(10000))){
          util.toast("单份发票最高开票限额一万元");
          return;
        }

        sessionStorage.setItem(
          "InvoiceInfo",
          JSON.stringify({
            pay_nos: this.selectOrder.map(item => item.payNo),
            selectOrderTotalMoney:this.selectOrderTotalMoney
          })
        );
        this.$router.push({name:"invoice_apply"})
      }
    }
  }
};
</script>

<style lang="scss">
.invoice-order {

  &-checkbox {
    display: inline-block;
    width: 0.29rem;
    height: 0.29rem;
    border-radius: 0.06rem;
    cursor: pointer;
    background-image: url(./images/checkbox2.png);
    background-size: 100%;
    background-repeat: no-repeat;

    &--active {
      background-image: url(./images/checkbox1.png);
    }
  }

  &__list {
    font-size: 0.229rem;
    line-height: 0.5rem;
    color: #999999;
    margin-bottom: 2.71rem;

    &__item {
      padding: 0.44rem 6% 0.44rem 5.2%;
      border-bottom: 0.02rem solid #e6e6e6;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    span {
      font-size: 0.375rem;
      color: #1394cc;
    }

    ul {
      padding-left: 0;
      list-style: none;
      margin-bottom: 0;

      li:before {
        content: "";
        display: inline-block;
        width: 0.07rem;
        height: 0.07rem;
        margin-right: 0.37rem;
        border-radius: 50%;
        vertical-align: middle;
        background-color: #999999;
      }
    }

    .mugen-scroll {
      font-size: 0.25rem;
      line-height: 0.7rem;
      height: 0.7rem;
      color: #333333;
      text-align: center;
      background-color: #f4f4f4;
    }
  }

  %button-base {
    width: 93.333%;
    display: block;
    text-align: center;
    background-color: #20a3dc;
    border-radius: 0.06rem;
    border: none;
    line-height: 1;
    color: #ffffff;
  }

  &__bar {
    width: 100%;
    max-width: 10rem;
    position: fixed;
    bottom: 0;
    border-top: 0.02rem solid #e6e6e6;
    padding-bottom: 0.2rem;
    background-color: #fff;
    font-size: 0.22rem;

    p {
      padding: 0.29rem 6% 0.29rem 5.2%;
      border-bottom: 0.02rem solid #e6e6e6;
      .num {
        color: #e50000;
      }
    }

    .select-all {
      font-size: 0.24rem;
      display: flex;
      align-items: center;
      padding: 0 6% 0 5.2%;
      margin: 0.22rem 0;

      .invoice-order-checkbox {
        margin-right: 0.11rem;
      }
    }

    button {
      @extend %button-base;
      font-size: 0.36rem;
      height: 0.8rem;
      margin: 0 auto;
      width: 90% !important;
    }
  }
}
</style>
