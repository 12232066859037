<template>
  <div class="invoice-success">
    <invoice-header text="开具电子发票"></invoice-header>
    <img src="./images/success.png" alt />
    <p>提交成功</p>
  </div>
</template>

<script>
import util from "../../commons/util";
export default {
  components: {
    "invoice-header": require("./header").default
  },
  created() {
    let ReturnInfo = sessionStorage.getItem("ReturnInfo");
    if (ReturnInfo) {
      ReturnInfo = JSON.parse(ReturnInfo);
      util.toast(`正在返回${ReturnInfo.page}页面`);
      setTimeout(() => {
        this.$router.push({ name: ReturnInfo.routeName });
      }, 3000);
    }
  }
};
</script>

<style lang="scss">
.invoice-success {
  .invoice-header__left {
    visibility: hidden;
  }

  img {
    display: block;
    width: 1.84rem;
    height: 1.64rem;
    margin: 2.86rem auto;
    margin-bottom: 0;
  }
  p {
    font-size: 0.312rem;
    margin-top: 0.25rem;
    color: #666666;
    text-align: center;
  }
}
</style>
